<template>
  <div class="landingpage">
    <img src='../assets/img/bg1.png'/>
    <div class='buttonBox'>
      <div class="landingPage">
        <div class="landingPageUl">
          <ul class='sub1'>
            <li class="telMsg">好友<em>{{sharedName}}</em>邀请您注册</li>
            <li><input type='tel' class="mobilePhone" placeholder='请输入您的手机号码' maxLength="11" v-model="mobilePhone" /></li>
            <li class='error'>{{error}}</li>
            <li class='registerBtn'><img @click="registerBtn()" src='../assets/img/btn1.png'/></li>
            <li class='xieyi'>我已阅读并同意<span>《用户服务协议》</span></li>
            <!-- <li class='login'>已有账号？<a href="">登录</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <img src='../assets/img/bg3.png'/>

    <div class="popBox" v-if="isPopBox1">
    <div class="contentBox">
      <img src="../assets/img/frame-bg.png" class="bg1">
      <img src="../assets/img/invitePopBg.png" class="bg2" style="display:none;">
      <img src="../assets/img/close1.png" class="popBoxClose" @click="close(1)">
      <div class="contentBoxDiv" style="display: none;">
            <p class="contentBoxDivError"></p>
            <img src="../assets/img/ok.png" class="ok" style="display:none">
            <p class="msg" style="display:none">恭喜您，注册成功</p>
        <a href="javascript:;" class="popBtn popBtnTwo"></a>
      </div>
      <div class="errorMsg" style="padding-top: 10px;">
        <p>您已注册了账号可直接登录</p>
        <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMzMwMjQxNA==&scene=110#wechat_redirect" class="popBtn popBtnOne colseBtn errorMsgA">关注公众号</a>
      </div>
    </div>
  </div>
   <div class="popBox1" v-if="isPopBox">
      <div class="contentBox">
        <!--短信验证码-->
        <img src="../assets/img/close1.png" class="popBoxClose1" @click="close(2)"  />
        <div class="getCode" v-if="isPopBoxCode">
          <input type="tel"  v-model="code" class="mobileCode" placeholder="请输入短信验证码" maxlength="6" style="width:75%;" />
          <input type="button" @click="getCode('SMS',1)" :value="times" class="getPhoneVcode"/>
          <p class="getCodeP">验证码已发送到{{mobilePhone}}手机号</p>
          <p class="contentBoxDivError">{{popBoxError}}</p>
          <a href="javascript:;" class="popBtn longBtn popBtnTwo"  @click="register()">立即注册</a>
        </div>
        <!--图形验证码-->
        <div class="getImgCode" v-if="isImgCode">
            <p class="imgCodeTitle">请输入图形验证码</p>
            <p class="imgCodeError"></p>
          <img :src="vcodeImg" alt="" @click="getImg('SMS',1)" class="valicode_img" />
          <!-- <img src="" alt="点击刷新" class="valicode_img" onclick="getValicode()" align="absmiddle"> -->
          <p class="valicodeClick" @click="captcha()">看不清？点击刷新<img src='../assets/img/refresh.png'/></p>
          <input  v-model="imgCode" @blur="handleBlur()" type="tel" class="picCode" placeholder="请输入验证码" maxlength="4" />
          <p class="contentBoxDivError">{{popBoxError}}</p>
          <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMzMwMjQxNA==&scene=110#wechat_redirect" class="popBtn longBtn popBtnTwo"  @click="getCode('SMS',0)">确定</a>
        </div>
      </div>
  </div>

  </div>
</template>
<script>

import { getPublicKey,checkUserExist,sendSmsBeforLogin,captcha,register,setLoginPwd,login} from "../services/api";
import $ from 'jquery'
import basic from '../utils/basic';

export default {
  data() {
    return {
      mobilePhone:'',
      error:'',
      isCode:false,
      isImgCode:false,
      isSendCode:false,
      isPopBox:false,
      isPopBox1:false,
      isPopBoxCode:false,
      sharedName:'',
      times:"获取短信验证码",
      canSend:true,
      phone:'',
      isDisplayPicCode:"",
      imgCode:'',
      picCodeKey:'',
      vcodeImg:'',
      popBoxError:'',
      code:''
    };
  },
  created: function() {
    var _this = this;
    _this.getPublicKey();
    // _this.activityQrcodeConfigData();//获取分享人的信息
  },
  methods: {
    async getPublicKey() {//获取开启加密
      const data = await getPublicKey({});
      if (data.code === 1) {
        this.key = data.data.data;
      } else {
        this.$toast(msg);
      }
    },
    // async activityQrcodeConfigData() {//获取邀请人姓名
    //   // var jsencrypt = new JSEncrypt();
    //   // jsencrypt.setPublicKey(this.key);
    //   var params = {"mobilePhone":'130000000'};
    //   const datas =await activityQrcodeConfigData({ params: params,type:1 });
    //   //const data={"code":1,"data":{"inviteMobile":"","inviteType":0,"inviteUserId":"MzQ=","inviteUserName":"*华"},"msg":""}
    //   if (datas.code === 1) {
    //     this.sharedName = datas.data.inviteUserName;
    //   } else {
    //     this.$toast(datas.msg);
    //   }
    // },
    registerBtn(){//立即注册按钮点击
      if(this.mobilePhone==""){
        this.error="*手机号不能为空"
      }else{
        this.checkUserExist();//校验手机号是否存在
      }
    },
    async checkUserExist() {//
      const _this=this;
      console.log( _this.mobilePhone)
      var mobilePhone = _this.mobilePhone.replace(/\s+/g, "");
      var jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(_this.key);
      const params = jsencrypt.encrypt('{"mobilePhone":"' + mobilePhone + '"}');
      console.log(mobilePhone)
      console.log(params+'====')
      const data = await checkUserExist({ params: params, type: 1});
      if (data.code == 1) {
        var datas = data.data;
        if (datas.isExist == 1) {//已经注册
          _this.isPopBox=true;
        }
        _this.isDisplayPicCode = datas.isDisplayPicCode;
        if (datas.isDisplayPicCode == 0) {   //不显示图像验证码
          _this.isPopBox=true
          _this.isPopBoxCode=true
        } else if (datas.isDisplayPicCode == 1) {   //显示图形验证码
          _this.isImgCode = true;
          _this.isPopBox=true
          _this.captcha();
        }
      }
    },
     async captcha() {//图形验证码
      var _this=this;
      // var jsencrypt = new JSEncrypt();
      // jsencrypt.setPublicKey(this.key);
      var params = {"mobilePhone":'130000000'};
      const datas =await getPublicKey({ params: params,type:1 });
    
          var _data = datas.data;
          var vcode = 'data:image/png;base64,' + _data.cs;
          _this.vcodeImg = vcode;
          _this.picCodeKey = _data.ts;
          _this.imgCode = "";
          _this.firstShow = false;
          _this.thirdShow = false;
          _this.secondShow = true;
    },
     // 获取短信验证码
    getCode(name, type) {
      console.log("000")
      var _this = this;
     
      if (_this.canSend == true) {

        if (_this.isExist == 0) {    //注册验证码
          var smsType = 'REGISTER_' + name;
        } else if (_this.isExist == 1) {   //登录验证码
          var smsType = 'QUICK_LOGIN_' + name;
        }
        if (type == 1) {
          _this.codeType = name;
          _this.imgCode = "";
          if (_this.isDisplayPicCode == 1) {
            _this.checkUserExist();
          } else {
            _this.getCode(name, 0);
          }
        } else if (type == 0) {
           _this.isPopBox=true
       _this.isPopBoxCode=true
        _this.isImgCode = false
          _this.phone=_this.mobilePhone
          _this.canSend = false;
          // var jsencrypt = new JSEncrypt();
          // jsencrypt.setPublicKey(this.key);
          var params = {"mobilePhone":'130000000'};
          // const { status, data } = await sendSmsBeforLogin({ params: params,sendSmsBeforLogin:smsType,picCodeKey:picCodeKey,picCode:this.imgCode});
          // const { code, data, msg } = data;
          const data={"code":1,"data":{},"msg":"发送成功"}
          //if (+code === 1) {
            // _this.thirdShow = true;
            _this.secondShow = false;
            _this.phoneCodeShow = true;
            // _this.$refs.code.focus()
            //document.getElementById("codeDiv").style.opacity = "inherit";
            var myTime = 120;
            if (name == "SMS") {
              console.log(name)
              //倒计时
              var timer = setInterval(function() {
                if (myTime == 1) {
                  clearInterval(timer);
                  _this.times = '获取短信验证码';
                  _this.canSend = true;
                } else {
                  myTime--;
                  _this.times = myTime + 's后重新获取';
                }
              }, 1000);
              console.log(_this.times)
               console.log("222")
            } 
          // } else {
          //   _this.popBoxError=data.msg;
          // }
        }
      }
    },
    async register() {

      var _this=this;
      var userName, code, params;
      if (_this.key == "") {
          userName = _this.mobilePhone.replace(/\s+/g, "");
          code = _this.code;
          params = { userName: userName, code: code };
        } else {
          var jsencrypt = new JSEncrypt();
          jsencrypt.setPublicKey(_this.key);
          userName = _this.phone.replace(/\s+/g, "");
          params = jsencrypt.encrypt('{"mobilePhone":"' + userName + '","managerMobile":"' + _this.$route.query.managerPhone + '","code":"' + _this.code + '"}');
        }

         const datas= await register({ params: params, role: 1, userNature: 1, wechat: _this.$route.query.wechat});
          if (datas.code === 1) {
                //缓存起来，后续使用
                localStorage.setItem('jroiqohoi', datas.data.jroiqohoi);
                localStorage.setItem('jwtToken', datas.data.jwtToken);
                localStorage.setItem('userId', datas.data.user_id);
                 _this.isPopBox = false
                 _this.isPopBox1 = true
          } else {
            this.$toast(datas.msg);
          }
    },
    close(type){
      if(type==1){
        this.isPopBox1=false;
      }
      if(type==2){
        this.isPopBox=false;
      }
     
    },
     handleBlur() {
      window.scroll(0, 0);
    },
  
  }
};
</script>
<style lang="css" scoped>
.imgCodeTitle{color:#fff}
.landingpage img{width: 100%;margin-bottom: -0.25rem;}
.landingPage{background: #540bb0}
.landingPage .landingPageConTop{width: 96%;margin-left: 2%;}
.landingPageUl{width: 90%;margin-left: 2%;padding-top: 1rem;background: #681fd0;border: 0.5rem solid #7b41f1;border-radius: 2rem;box-shadow: #553189 0 0 3rem 0.5rem inset;}
.landingPageConTop div{width: 46%;display: inline-block;position: relative;font-size:0.8rem;color:#fff;display:inline-block;min-height:10rem;}
.landingPageConTop div:first-child{margin-right: 8%;}
.landingPageConTop div p:first-child{margin-top: 4rem;}
.landingPageConTop div .rate em{font-size: 3rem;font-style: inherit;}
.landingPageConTop div p{position: absolute;top:34%;width: 100%;}
.landingPageConTop div p span{display: inline-block;text-align: center;width: 100%}
.landingPageConTop .insurance .num{font-size: 3rem;}
.landingPageConTop .insurance .num em{color:#ffd200;font-style: inherit;}
.buttonBox .sub1{width: 86%;margin-left: 7%;}
.buttonBox .sub1 li{width:100%;margin-bottom:0.5rem;display: flex;align-items: center;font-size:0.8rem;}
.buttonBox .sub1 li:first-child{text-align: center;display: inherit;color:#fff;}
.buttonBox .sub1 li:first-child em{color: #fece1a;font-style: inherit;font-weight: bold;}
.buttonBox .sub1 .voiceCode{color:#fff;justify-content:flex-end;}
.buttonBox .sub1 input{width:90%;height:0.5rem;line-height:0.5rem;border-radius:2rem;border:0;padding:1rem 5%;color:#999;}
.buttonBox .sub1 .code{width:45%;margin-right:5%;display:inline-block;}
.buttonBox .sub1 .images{width:40%;height:4rem;line-height:4rem;display:inline-block;background:#ededed;border-radius:4rem;}
.buttonBox .sub1 button{width:40%;border:0;}
.buttonBox .sub1 li span{text-decoration:underline;}
.buttonBox .sub1 .xieyi{color:#fff;margin-bottom:1rem!important;padding-left: 1.5rem;line-height: 0;}
.buttonBox .sub1 .xieyi span{color:#fee60a;text-decoration: initial;}
.buttonBox .sub1 .registerBtn{color:#fff;justify-content:center;margin-bottom:0;}
.buttonBox .error{color:#ff0000;padding-left:1.5rem;}
.buttonBox .login{justify-content:flex-end;color:#fff;text-decoration:underline;}
.buttonBox .login a{color:#ecdf3e;}

.popBox,.popBox1{height: 100%;width: 100%;background-color: rgba(0,0,0,.8);position: fixed;top: 0;color: #666;z-index: 1019;}
.popBox .contentBox{width:80%;position: fixed;top: 20%;left: 50%;margin-left: -40%;text-align: center;font-size: 18px;border-radius: 0.25rem;}
.popBox .contentBox .contentBoxDiv,.errorMsg{padding-top: 10px;background: #fff;margin-top: -5px;padding:0 0 2rem 0;position: relative;}
.popBox .contentBox .contentBoxDiv .time{font-size: 12px;color:#666;padding-bottom: 10px;}
.popBox .contentBox .contentBoxDiv .time em{font-style: inherit;color:#8000ff;}
.popBox .contentBox .contentBoxDiv .ok{position: absolute;top: -20%;left: 50%;width: 15%;margin-left: -7.5%;}
.popBox .contentBox .contentBoxDiv .ok img{width: 55%;}
.popBox .contentBox .contentBoxDiv .msg{padding-top: 25px;width: 70%;margin: 0 auto 20px auto;}
.contentBoxDivError,.imgCodeError{color:red;font-size: 14px;padding: 10px 0;}
.popBox .popBtn{color: #fff;border-radius: 38px;font-size: 12px;height: 35px;line-height: 35px;display: inline-block;width: 46%;text-decoration: inherit;margin-top: 10px;box-shadow: 0px 2px 10px #666;}
.popBtnOne{background: linear-gradient(to right, #f55f53 , #f4a23c);}
.popBtnTwo{background: linear-gradient(to right, #6e50e5 , #f74b98);}
.popBox1 .getCode{margin-top: 20px;}
.popBox1 .getCode .mobileCode,.popBox1 .getImgCode input{padding:10px 5%;width: 50%;height: 15px;line-height: 14px;border-radius: 45px;border: 0;color: #999;border:1px solid #691db4;}
.popBox1 .getCode p{font-size: 12px;padding-top: 30px;}
.popBox1 .getImgCode .valicode_img{width: 50%;border:1px solid #dfdfdf;}
.popBox1 .getImgCode p{font-size: 16px;padding: 10px 0;}
.popBox1 .getImgCode input{width: 70%}
.popBox1 .getImgCode .valicodeClick img{width: 5%;margin-bottom: -2px;padding-left: 3px;}
.popBox1 .contentBox{width:80%;min-height: 140px;position: fixed;top: 30%;left: 10%;text-align: center;font-size: 18px;border-radius: 0.25rem;background-image: url('../assets/img/invitePopBg.png');background-repeat: no-repeat;background-size: 100%;background-color: #fff;}
.popBox1popBox1 .popBoxClose,.popBox1 .popBoxClose1,.popBox .popBoxClose{width: 7%;position: absolute;right: 0; top: -1.5rem;}
.getPhoneVcode{font-size:0.8rem;color: #691db3;position: absolute;right: 30px;top: 30px;border:none;background:none;}
.longBtn {
    width: 80%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    margin: 10px auto 20px;
    border-radius: 50px;
    font-size: 15px;
    color: #fff;
}
input{border:none;}

</style>
